import React from "react";

const UploadIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M2 7.53846V10H11V7.53846M6.5 7.53846V2M6.5 2L4.25 4.46154M6.5 2L8.75 4.46154"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default UploadIcon;
