import React from "react";

const HomeIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M2.20001 5.5L6.20001 2L10.7 5.5V11H2.20001V5.5Z"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default HomeIcon;
