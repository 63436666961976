import React from "react";
const PenIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M6.5 9.5L11.5 4.5L9 2L3.5 7.5L2 11L6.5 9.5Z"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default PenIcon;
