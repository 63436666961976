import React from "react";
import { useParams } from "react-router-dom";
import { MasonryComponent } from "../../Components/UX/Masonry";
import { RouteName } from "../../../Routes";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import service from "../../../Mono/service";
import {
  updateCache,
  useGetPaginationSuggestedPostsFromTags,
  useGetPost,
  useGetPostReplies,
} from "../../../Mono/queryHooks";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import {
  Text,
  Box,
  HStack,
  Spacer,
  TextVariants,
  Touchable,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import {
  Comment,
  CommentsList,
  MasonryLoadingComponent,
  PostComponent,
} from "../../Components/UX";
import { Post, User } from "../../../Mono/types";
import { Icon, IconType } from "../../assets/icons";
import { ShareModal } from "../../Components/UX/ShareModal";

const useGetPostPreviousReplies = (id: Post["id"]) => {
  const [previousReplies, setPreviousReplies] = React.useState<Post[]>([]);

  const getPreviousReply = (id: Post["id"]) => {
    service
      .api()
      .posts.getPost(id)
      .then((post) => {
        console.log("post");
        setPreviousReplies((posts) => [post, ...posts]);
        if (post.replyToId) getPreviousReply(post.replyToId);
      });
  };

  React.useEffect(() => {
    setPreviousReplies([]);
    if (id) {
      getPreviousReply(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    previousReplies,
    setPreviousReplies,
  };
};

export const PostPage = () => {
  let { id, pagination } = useParams();
  const mode = useGetWindowMode();
  const user = service.state.user;

  const [shareModalOpen, setShareModalOpen] = React.useState(false);
  const postUrl = `https://yuragura.com/posts/${id}`;

  const onPressShare = async () => {
    setShareModalOpen(true);
  };
  const onCloseShare = () => {
    setShareModalOpen(false);
  };

  React.useEffect(() => {
    if (!id) return;
    if (service.state.posts.length < 2) {
      setTimeout(function () {
        window.location.href = `yuragura://post/${id}`;
      }, 500);
    }
  }, []);

  const [commentsOpen, setCommentsOpen] = React.useState<boolean>(false);

  const { val: originalPost, setVal: setOriginalPost } = useGetPost(id || "");
  const { val: rePostedPost, setVal: setRePostedPost } = useGetPost(
    originalPost?.rePostId || ""
  );
  const post = rePostedPost || originalPost;
  const setPost = rePostedPost ? setRePostedPost : setOriginalPost;
  const { previousReplies, setPreviousReplies } = useGetPostPreviousReplies(
    post?.replyToId || ""
  );

  const {
    posts: postReplies,
    endReached: repliesEndReached,
    setPosts: setPostReplies,
    getMore: getMoreReplies,
    clear: clearReplies,
  } = useGetPostReplies(post?.rePostId || post?.id || "");

  const tags = post?.tags.map((tag) => tag);

  const {
    posts: similarPosts,
    getMore,
    clear,
  } = useGetPaginationSuggestedPostsFromTags({
    tags: tags?.length ? tags : undefined,
    currentPagination: pagination ? Number(pagination) : 1,
  });

  const updatePost = (updatedPost: Post) => {
    updateCache([updatedPost]);

    if (post?.id === updatedPost.id) {
      setPost(updatedPost);
      return;
    }

    if (postReplies?.find((p) => p.id === updatedPost.id)) {
      setPostReplies(
        postReplies.map((p) => (p.id === updatedPost.id ? updatedPost : p))
      );

      updateCache([updatedPost]);
      return;
    }

    if (previousReplies.find((p) => p.id === updatedPost.id)) {
      setPreviousReplies(
        previousReplies.map((p) => (p.id === updatedPost.id ? updatedPost : p))
      );
      return;
    }
  };

  const savePost = async (savingPost: Post) => {
    if (!post || !user) return;

    const savedPost = {
      ...savingPost,
      isSaved: !savingPost.isSaved,
      saveCount: savingPost.isSaved
        ? savingPost.saveCount - 1
        : savingPost.saveCount + 1,
    };

    if (savingPost.isSaved) {
      service.api().posts.deleteSave(savingPost.id);
    } else {
      service.api().posts.createSave(savingPost.id);
    }

    updatePost(savedPost);
  };

  const rePost = (rePost: Post) => {
    if (!post || !user) return;

    const postChange = {
      ...rePost,
      isRePosted: !rePost?.isRePosted,
      rePostCount: rePost?.isRePosted
        ? rePost?.rePostCount - 1
        : rePost?.rePostCount + 1,
    };

    if (rePost.isRePosted) {
      service.api().posts.undoRePost(rePost.id);
    } else {
      const formData = new FormData();
      formData.append("rePostId", rePost.id);
      service.api().posts.createPost(formData);
    }

    updatePost(postChange);
  };

  const linkTo = useLinkTo();

  const onCreateComment = () => {
    if (post) {
      linkTo(RouteName.CreatePost, { replyToId: post.id });
    }
  };

  const onOpenComments = () => {
    setCommentsOpen(true);
  };

  const onClickProfile = (user: User) => {
    linkTo(RouteName.Profile, { hangName: user.hangName });
  };

  const onClickPost = (post: Post) => {
    const postIndex = similarPosts?.findIndex((p) => p.id === post.id);
    const currentPagination = pagination ? Number(pagination) : 1;
    clearReplies();
    linkTo(RouteName.Post, {
      postId: post.id,
      pagination: currentPagination + (postIndex ? postIndex + 1 : 1),
    });
  };

  const onClickTag = (tag: string) => {
    linkTo(RouteName.Tag, { tag });
  };

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  const [show, setShow] = React.useState<boolean>(false);
  const [currentPostId, setCurrentPostId] = React.useState<string>("");

  React.useEffect(() => {
    if (!currentPostId && post) {
      setCurrentPostId(post?.id);
    }
    if (post?.id === id) {
      return;
    }
    setShow(false);
    setTimeout(() => {
      setShow(true);
      if (similarPosts?.length) {
        clear();
        getMore();
      }
      if (post?.id) setCurrentPostId(post?.id);
    }, 200);
  }, [id]);

  if (!show || !post) return null;

  return (
    <PageContainer flowInOnRender onEndReached={getMore}>
      <Spacer y={mode === "web" ? 110 : 10} />

      <Box center>
        <Box
          style={{
            maxWidth: 630,
          }}
        >
          {previousReplies?.length ? (
            <Box spacing={10}>
              <PreviousReplies
                previousReplies={previousReplies}
                rePost={rePost}
                savePost={savePost}
                onClickProfile={onClickProfile}
                onClickPost={onClickPost}
              />
            </Box>
          ) : null}
          <Spacer y={10} />
          {post && (
            <Box spacing={5}>
              <PostComponent
                post={post}
                savePost={() => savePost(post)}
                rePost={() => rePost(post)}
                onClickProfile={() =>
                  post.user ? onClickProfile(post.user) : null
                }
                onPressImage={() => {}}
                onPressUrl={openLink}
                onShare={onPressShare}
                onClickTripleDot={() => {}}
              />
            </Box>
          )}

          {postReplies && (
            <CommentsList
              posts={postReplies}
              commentsOpen={commentsOpen}
              savePost={savePost}
              rePost={rePost}
              onClickProfile={onClickProfile}
              onClickPost={onClickPost}
              onPressImage={() => {}}
              onPressUrl={openLink}
            />
          )}

          <SeeMore
            display={
              (commentsOpen && !repliesEndReached) ||
              (!commentsOpen && postReplies && postReplies?.length > 3)
            }
            onClick={() =>
              commentsOpen && !repliesEndReached
                ? getMoreReplies()
                : !commentsOpen && postReplies && postReplies?.length > 3
                ? onOpenComments()
                : null
            }
          />

          <Box spacing={{ left: 10, right: 10, top: 20, bottom: 20 }}>
            <CommentButton user={user} onCreateComment={onCreateComment} />
          </Box>

          <PostTags tags={tags || null} onClickTag={onClickTag} />
        </Box>
      </Box>
      <Box height={50} />

      <Box center>
        <Box
          style={{
            maxWidth: 900,
            width: "100%",
          }}
        >
          <OtherPosts similarPosts={similarPosts} onClickPost={onClickPost} />
        </Box>
      </Box>
      <ShareModal url={postUrl} open={shareModalOpen} onClose={onCloseShare} />
    </PageContainer>
  );
};

const PreviousReplies = ({
  previousReplies,
  rePost,
  savePost,
  onClickProfile,
  onClickPost,
}: {
  previousReplies: Post[];
  rePost: (post: Post) => void;
  savePost: (post: Post) => void;
  onClickProfile: (post: User) => void;
  onClickPost: (post: Post) => void;
}) => (
  <>
    {previousReplies.map((post) => (
      <>
        <Comment
          post={post}
          displayCommentLine
          savePost={() => savePost(post)}
          rePost={() => rePost(post)}
          onClickProfile={() => post.user && onClickProfile(post.user)}
          onClickPost={() => onClickPost(post)}
          onPressImage={() => {}}
          onPressUrl={() => {}}
        />

        <Box
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
          }}
          width={40}
          height={50}
          spacing={{
            top: 10,
            bottom: 10,
          }}
        >
          <Box width={2} borderRadius={100} color={Colors.Grey2} />
        </Box>
      </>
    ))}
  </>
);

const CommentButton = ({
  user,
  onCreateComment,
}: {
  user: User | null;
  onCreateComment: () => void;
}) => (
  <Touchable onPress={onCreateComment}>
    <HStack center>
      {user?.avatar ? (
        <img
          src={user?.avatar}
          style={{ width: 40, height: 40, borderRadius: 15 }}
        />
      ) : null}
      <Spacer x={10} />
      <Box color={Colors.Black2} borderRadius={15} style={{ flex: 1 }}>
        <Box
          spacing={{
            left: 15,
            right: 20,
            top: 10,
            bottom: 10,
          }}
        >
          <Text
            color={Colors.Grey}
            size={TextVariants.Small}
            text="コメントを投稿"
          />
        </Box>
      </Box>
    </HStack>
  </Touchable>
);

const SeeMore = ({
  display,
  onClick,
}: {
  display: boolean | null;
  onClick: () => void;
}) =>
  display ? (
    <Box style={{ alignItems: "center" }}>
      <Touchable onPress={onClick}>
        <HStack>
          <Box center>
            <Text
              color={Colors.White}
              size={TextVariants.Small}
              text="もっと見る ..."
            />
          </Box>
        </HStack>
      </Touchable>
    </Box>
  ) : null;

const PostTags = ({
  tags,
  onClickTag,
}: {
  tags: string[] | null;
  onClickTag: (tag: string) => void;
}) =>
  tags ? (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
    >
      {tags?.map((tag) => (
        <Touchable onPress={() => onClickTag(tag)}>
          <Box spacing={10}>
            <HStack center>
              <Icon size={16} type={IconType.Hashtag} />
              <Spacer x={5} />
              <Text color={Colors.Grey} size={TextVariants.Small} text={tag} />
            </HStack>
          </Box>
        </Touchable>
      ))}
    </Box>
  ) : null;

const OtherPosts = ({
  similarPosts,
  onClickPost,
}: {
  similarPosts: Post[] | null;
  onClickPost: (post: Post) => void;
}) =>
  similarPosts?.length ? (
    <MasonryComponent posts={similarPosts} onClick={onClickPost} />
  ) : (
    <MasonryLoadingComponent />
  );
