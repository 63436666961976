import React from "react";

const ShieldIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    {/* <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
    <path
      d="M3 2V7.5L6.5 11L10 7.5V2H3Z"
      stroke={color}
      stroke-linejoin="round"
    />
  </svg>
);

export default ShieldIcon;
