import React from "react";
import {
  ImageContent,
  postContentSplit,
} from "../../../Mono/tools/postExtract";
import { Post } from "../../../Mono/types/Post";
import { Colors } from "../../../Mono/consts/Colors";
import { Icon, IconType } from "../../assets/icons";
import { PostField } from "./PostField";
import { Box } from "../UI/Box";
import { Text, TextVariants } from "../UI/Text";
import { Spacer } from "../UI/Spacer";
import { HStack } from "../UI/HStack";
import { Touchable } from "../UI/Touchable";

export const PostComponent = ({
  post,
  savePost,
  rePost,
  onClickProfile,
  onPressImage,
  onPressUrl,
  onShare,
  onClickTripleDot,
}: {
  post: Post;
  savePost: () => void;
  rePost: () => void;
  onClickProfile: () => void;
  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
  onShare: () => void;
  onClickTripleDot: () => void;
}) => {
  const postContent = postContentSplit(post.content);

  const avatarUri = post?.user?.avatar || "";
  const userName = post?.user?.name || "[削除]";
  const hangName = post?.user?.hangName || "";
  const isModerator = post?.user?.role === "moderator";
  const commentCount = post?.commentCount || 0;
  const saveCount = post?.saveCount || 0;
  const rePostCount = post?.rePostCount || 0;
  const isSaved = post?.isSaved || false;
  const isRePosted = post?.isRePosted || false;

  return (
    <>
      <HStack spaceBetween center>
        <HStack>
          <Spacer x={10} />
          <Touchable onPress={onClickProfile}>
            <HStack center>
              <Avatar uri={avatarUri} />
              <Spacer x={10} />
              <UserName name={userName} />
              <Spacer x={5} />
              <ModerationBadge isModerator={isModerator} />
              <Spacer x={5} />
              <HangName hangName={hangName} />
            </HStack>
          </Touchable>
        </HStack>
        <HStack>
          {/* <PostOptions onClickTripleDot={onClickTripleDot} /> */}
          <Spacer x={10} />
        </HStack>
      </HStack>
      <Spacer y={10} />
      <PostContent
        content={postContent}
        onPressImage={onPressImage}
        onPressUrl={onPressUrl}
      />
      <Spacer y={10} />
      <HStack spaceBetween>
        <HStack>
          <Spacer x={10} />
          <PostActivity
            saveCount={saveCount}
            rePostCount={rePostCount}
            commentCount={commentCount}
          />
        </HStack>
        <HStack>
          <ShareButton onShare={onShare} />
          <Box width={20} />
          <RepostButton isRePosted={isRePosted} rePost={rePost} />
          <Box width={20} />
          <SaveButton isSaved={isSaved} savePost={savePost} />
          <Spacer x={10} />
        </HStack>
      </HStack>
    </>
  );
};

//
//
//

const Avatar = ({ uri }: { uri: string | null }) =>
  uri ? (
    <img
      src={uri}
      style={{
        borderRadius: 15,
        width: 40,
        height: 40,
        backgroundColor: Colors.Black2,
      }}
    />
  ) : (
    <Box color={Colors.Black2} borderRadius={15} width={40} height={40} />
  );

const UserName = ({ name }: { name: string }) => (
  <Text color={Colors.White} bold size={TextVariants.Small} text={name} />
);

const HangName = ({ hangName }: { hangName: string }) => (
  <Text
    color={Colors.Grey}
    size={TextVariants.Smaller}
    text={hangName ? `@${hangName}` : "@ --"}
  />
);

const ModerationBadge = ({ isModerator }: { isModerator: boolean }) =>
  isModerator ? (
    <Box
      color={Colors.Pink}
      style={{
        width: 22,
        height: 22,
        borderRadius: 30,

        alignItems: "center",
        justifyContent: "center",
        display: "flex",

        zIndex: 10,
      }}
    >
      <Icon type={IconType.Shield} size={15} />
    </Box>
  ) : null;

const PostOptions = ({
  onClickTripleDot,
}: {
  onClickTripleDot: () => void;
}) => (
  <Touchable onPress={onClickTripleDot}>
    <Box
      spacing={{
        left: 10,
        top: 10,
        bottom: 10,
      }}
    >
      <Icon type={IconType.TripleDot} size={20} />
    </Box>
  </Touchable>
);

const PostContent = ({
  content,
  onPressImage,
  onPressUrl,
}: {
  content: any; // PostContent[] | null;
  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
}) => {
  return (
    <Box
      borderRadius={15}
      style={{
        flex: 1,
        overflow: "hidden",
      }}
    >
      {content ? (
        <PostField
          postContent={content}
          onPressImage={onPressImage}
          onPressUrl={onPressUrl}
          textSpacing={5}
          textSize={18}
        />
      ) : (
        <Text color={Colors.Grey} text="[削除されました]" />
      )}
    </Box>
  );
};

const ShareButton = ({ onShare }: { onShare: () => void }) => (
  <Touchable onPress={onShare}>
    <Icon size={23} type={IconType.Share} color={Colors.Grey} />
  </Touchable>
);

const RepostButton = ({
  isRePosted,
  rePost,
}: {
  isRePosted: boolean;
  rePost: () => void;
}) => (
  <Touchable onPress={rePost}>
    <Icon
      size={23}
      type={IconType.Repost}
      color={isRePosted ? Colors.Green : Colors.Grey}
    />
  </Touchable>
);

const SaveButton = ({
  isSaved,
  savePost,
}: {
  isSaved: boolean;
  savePost: () => void;
}) => (
  <Touchable onPress={savePost}>
    <Icon
      size={23}
      type={isSaved ? IconType.SaveFilled : IconType.Save}
      color={isSaved ? Colors.Red : Colors.Grey}
    />
  </Touchable>
);

const PostActivity = ({
  saveCount,
  rePostCount,
  commentCount,
}: {
  saveCount: number;
  rePostCount: number;
  commentCount: number;
}) => (
  <HStack center>
    {saveCount ? (
      <>
        <Text color={Colors.White} size={TextVariants.Small} text={saveCount} />
        <Spacer x={5} />
        <Text color={Colors.Grey} size={TextVariants.Smaller} text="保存" />
        <Spacer x={10} />
      </>
    ) : null}

    {rePostCount ? (
      <>
        <Text
          color={Colors.White}
          size={TextVariants.Small}
          text={rePostCount}
        />
        <Spacer x={5} />
        <Text color={Colors.Grey} size={TextVariants.Smaller} text="リピート" />
        <Spacer x={10} />
      </>
    ) : null}

    {commentCount ? (
      <>
        <Text
          color={Colors.White}
          size={TextVariants.Small}
          text={commentCount}
        />
        <Spacer x={5} />
        <Text color={Colors.Grey} size={TextVariants.Smaller} text="コメ" />
        <Spacer x={10} />
      </>
    ) : null}
  </HStack>
);
