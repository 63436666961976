import React from "react";

const ProfileIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <circle cx="6.5" cy="6.5" r="5" stroke={color} />
  </svg>
);

export default ProfileIcon;
