import React from "react";
import { useScrollEnd } from "../../../hooks/useScrollEnd";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import { MasonryComponent } from "../../Components/UX/Masonry";
import { useGetUserReplies } from "../../../Mono/queryHooks";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Box, Text, TextVariants } from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import { Post } from "../../../Mono/types";

export const UserReplies = () => {
  const mode = useGetWindowMode();

  const { posts, getMore } = useGetUserReplies();

  const { isAtEnd, ref } = useScrollEnd(2);

  React.useEffect(() => {
    if (isAtEnd) getMore();
  }, [isAtEnd]);

  const linkTo = useLinkTo();
  const onClickPost = (post: Post) => {
    linkTo(RouteName.Post, { postId: post.id });
  };

  return (
    <div
      ref={ref}
      style={{
        overflowY: "auto",
        height: "100vh",
      }}
    >
      <Box
        style={{
          width: "100%",
          height: "100vh",
        }}
      >
        <Box
          style={{
            margin: 15,
          }}
          borderRadius={20}
          center
        >
          <Box
            style={{
              width: "100%",
              height: mode === "web" ? 500 : 350,
              borderRadius: 20,

              backgroundColor: Colors.Black3,
              alignItems: "center",
            }}
            center
          >
            <Text size={TextVariants.Larger} text="リプライ" />
          </Box>
        </Box>
        <Box center>
          <Box
            spacing={
              mode === "web"
                ? { left: 15, right: 15 }
                : {
                    left: 10,
                    right: 10,
                  }
            }
            style={{
              width: "100%",
              maxWidth: 900,
              zIndex: 1,
            }}
          >
            {posts ? (
              <MasonryComponent posts={posts} onClick={onClickPost} />
            ) : null}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
