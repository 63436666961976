import React from "react";
import { postContentSplit } from "../../../Mono/tools/postExtract";
import { Post } from "../../../Mono/types/Post";
import { Colors } from "../../../Mono/consts/Colors";
import { SmallPostField } from "./PostField";
import { Icon, IconType } from "../../assets/icons";
import { useGetPost } from "../../../Mono/queryHooks/posts";
import { Box, Text } from "../UI";
import { Touchable } from "../UI/Touchable";
import { HStack } from "../UI/HStack";
import { Spacer } from "../UI/Spacer";

const masonryPostCardStyle: React.CSSProperties = {
  overflow: "hidden",
  maxHeight: 400,
};

export const MasonryPostCard = ({
  post,
  onClick,
}: {
  post: Post;
  onClick: () => void;
}) => {
  const postContent = postContentSplit(post.content);
  const { val: rePost } = useGetPost(post.rePostId || "");
  const rePostContent = rePost?.content
    ? postContentSplit(rePost?.content)
    : null;

  return (
    <Box>
      {post.rePostId ? (
        <Box spacing={10}>
          <HStack>
            <Icon size={15} type={IconType.Repost} color={Colors.Grey} />
            <Spacer x={5} />
            <Text styles={{ color: Colors.Grey, margin: 0, fontSize: 10 }}>
              @{post.user?.hangName}
            </Text>
          </HStack>
        </Box>
      ) : null}
      <Touchable onPress={onClick}>
        <Box
          borderRadius={15}
          style={masonryPostCardStyle}
          color={Colors.Black2}
        >
          <HStack>
            <SmallPostField postContent={rePostContent || postContent} />
          </HStack>
        </Box>
      </Touchable>
    </Box>
  );
};
