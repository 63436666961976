import React from "react";
import { Box, HStack, Spacer, Text, TextVariants } from "../UI";
import { Colors } from "../../../Mono/consts/Colors";

export const ProfileInfoLoading = () => (
  <>
    <Box height={150} borderRadius={20} color={Colors.Black2} />
    <Box spacing={10}>
      <HStack spaceBetween>
        <Box
          color={Colors.Black3}
          style={{
            padding: 7,
            borderRadius: 35,
            marginTop: -60,
          }}
        >
          <Box
            color={Colors.Black2}
            style={{
              width: 80,
              height: 80,
              borderRadius: 1000,
            }}
          />
        </Box>
        <Spacer x={20} />
      </HStack>
      <Box
        spacing={{
          left: 10,
          right: 10,
          top: 10,
        }}
      >
        <Box width={70} height={5} color={Colors.White} borderRadius={15} />
      </Box>
      <Spacer y={15} />
      <Box spacing={{ left: 10, right: 10 }}>
        <Box width={70} height={10} color={Colors.Grey2} borderRadius={15} />
      </Box>

      <Spacer y={15} />
      <Box spacing={10}>
        <HStack>
          <HStack center>
            <Box
              width={20}
              height={20}
              color={Colors.Grey2}
              borderRadius={15}
            />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロー"
              size={TextVariants.Small}
            />
          </HStack>
          <Spacer x={20} />
          <HStack center>
            <Box
              width={20}
              height={20}
              color={Colors.Grey2}
              borderRadius={15}
            />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロワー"
              size={TextVariants.Small}
            />
          </HStack>
        </HStack>
      </Box>
    </Box>
  </>
);
