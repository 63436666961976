import React from "react";
import {
  ImageContent,
  postContentSplit,
} from "../../../Mono/tools/postExtract";
import { Post } from "../../../Mono/types/Post";
import { Colors } from "../../../Mono/consts/Colors";
import { Icon, IconType } from "../../assets/icons";
import { PostField } from "./PostField";
import { Box } from "../UI/Box";
import { Spacer } from "../UI/Spacer";
import { HStack } from "../UI/HStack";
import { Touchable } from "../UI/Touchable";
import { Text, TextVariants } from "../UI/Text";

interface CommentProps {
  post: Post | null;

  displayCommentLine?: boolean;
  savePost: () => void;
  rePost: () => void;
  onClickProfile: () => void;
  onClickPost: () => void;

  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
}

export const Comment = ({
  post,

  displayCommentLine,
  savePost,
  rePost,
  onClickProfile,
  onClickPost,
  onPressImage,
  onPressUrl,
}: CommentProps) => {
  const postContent = post?.content ? postContentSplit(post?.content) : null;

  const avatarUri = post?.user?.avatar || "";
  const userName = post?.user?.name || "[削除]";
  const isModerator = post?.user?.role === "moderator";
  const isComment = displayCommentLine || false;
  const commentCount = post?.commentCount || 0;
  const isRePosted = post?.isRePosted || false;
  const isSaved = post?.isSaved || false;

  return (
    <Touchable onPress={onClickPost}>
      <Touchable onPress={onClickProfile}>
        <HStack center>
          <Avatar uri={avatarUri} />
          <Spacer x={10} />
          <UserName name={userName} />
          <Spacer x={5} />
          <ModerationBadge isModerator={isModerator} />
          <Spacer x={5} />
        </HStack>
      </Touchable>
      <Spacer y={10} />
      <HStack>
        <Spacer x={20} />
        <CommentLine display={isComment} />
        <Spacer x={20} />

        <PostContent
          content={postContent}
          onPressImage={onPressImage}
          onPressUrl={onPressUrl}
        />
        <Spacer x={20} />
      </HStack>

      <Spacer y={20} />

      <HStack spaceBetween>
        <CommentCount count={commentCount} />
        <HStack>
          <RepostButton rePost={rePost} isRePosted={isRePosted} />
          <Spacer x={20} />
          <SaveButton savePost={savePost} isSaved={isSaved} />
        </HStack>
      </HStack>
    </Touchable>
  );
};

//
//
//

const Avatar = ({ uri }: { uri: string }) => (
  <img
    alt="avatar"
    src={uri}
    height={40}
    width={40}
    style={{
      backgroundColor: Colors.Black2,
      borderRadius: 15,
    }}
  />
);

const UserName = ({ name }: { name: string }) => (
  <Text color={Colors.Grey} size={TextVariants.Small} text={name} />
);

const ModerationBadge = ({ isModerator }: { isModerator: boolean }) =>
  isModerator ? (
    <Box
      color={Colors.Pink}
      style={{
        width: 22,
        height: 22,
        borderRadius: 30,

        alignItems: "center",
        justifyContent: "center",
        display: "flex",

        zIndex: 10,
      }}
    >
      <Icon type={IconType.Shield} size={15} />
    </Box>
  ) : null;

const CommentLine = ({ display }: { display: boolean }) =>
  display ? <Box width={2} borderRadius={100} color={Colors.Grey2} /> : null;

const PostContent = ({
  content,
  onPressImage,
  onPressUrl,
}: {
  content: any; // PostContent[] | null;
  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
}) => {
  return (
    <Box style={{ flex: 1 }}>
      {content ? (
        <PostField
          postContent={content}
          onPressImage={onPressImage}
          onPressUrl={onPressUrl}
          textSpacing={5}
          textSize={15}
        />
      ) : (
        <Text color={Colors.Grey} text="[削除されました]" />
      )}
    </Box>
  );
};

const CommentCount = ({ count }: { count: number }) => (
  <Text color={Colors.Grey} size={TextVariants.Small} text={`${count} コメ`} />
);

const RepostButton = ({
  rePost,
  isRePosted,
}: {
  rePost: () => void;
  isRePosted: boolean;
}) => (
  <Touchable onPress={rePost}>
    <Icon
      size={20}
      type={IconType.Repost}
      color={isRePosted ? Colors.Green : Colors.Grey}
    />
  </Touchable>
);

const SaveButton = ({
  savePost,
  isSaved,
}: {
  savePost: () => void;
  isSaved: boolean;
}) => (
  <Touchable onPress={savePost}>
    <Icon
      size={20}
      type={isSaved ? IconType.SaveFilled : IconType.Save}
      color={isSaved ? Colors.Red : Colors.Grey}
    />
  </Touchable>
);
