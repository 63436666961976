import React from "react";

const BottomIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      d="M3 5L6.5 8.5L10 5"
      stroke={color}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BottomIcon;
