import React from "react";

import {
  Box,
  Text,
  Spacer,
  TextVariants,
  ButtonVariants,
  Button,
} from "../../Components/UI";
import { TextInput } from "../../Components/UX";
import service from "../../../Mono/service";
import { checkUserNameErrors } from "../../../Mono/tools/checkUserNameIsValid";
import { User } from "../../../Mono/types";
import { Colors } from "../../../Mono/consts/Colors";
import { LoadingOverlay } from "../../Components/UX/LoadingOverlay";
import { useNavigate } from "react-router-dom";
import { FileUploadComponent } from "../../Components/UX/FileUplaodComponent";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";

export const ProfileEdit = () => {
  const navigate = useNavigate();
  const mode = useGetWindowMode();

  const [avatar, setAvatar] = React.useState<File | null>(null);
  const [background, setBackground] = React.useState<File | null>(null);
  const [username, setUsername] = React.useState<string>(
    service.state.user?.name || ""
  );
  const [description, setDescription] = React.useState<string>(
    service.state.user?.description || ""
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  const error = checkUserNameErrors(username);

  const submit = () => {
    setLoading(true);
    const formData = new FormData();
    if (avatar) formData.append("avatar", avatar, "avatar");
    if (background) formData.append("background", background, "background");

    formData.append("name", username);
    formData.append("description", description);

    service
      .api()
      .users.updateUser(formData)
      .then((r) => {
        navigate(`/users/${r.hangName}`);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const user: User | null = service.state.user
    ? {
        ...service.state.user,
        name: username,
        description: description,
        avatar: avatar
          ? URL.createObjectURL(avatar)
          : service.state.user?.avatar,
        background: background
          ? URL.createObjectURL(background)
          : service.state.user?.background,
      }
    : null;

  return (
    <>
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box center>
        <Box
          style={{
            width: "95%",

            maxWidth: 900,
          }}
        >
          {/* <ProfileInfo
            user={user}
            profileButtonText={"フォロー"}
            onProfileButtonClick={() => {}}
            onClickTripleDot={() => {}}
            buttonVariant={ButtonVariants.Black}
            bannerHeight={mode === "web" ? 250 : 150}
            onPressUrl={() => {}}
          /> */}
        </Box>
      </Box>

      <Box center>
        <Box
          style={{
            width: "95%",

            maxWidth: 400,
          }}
        >
          <Box spacing={15}>
            <Box borderRadius={15}>
              <Text color={Colors.Grey} size={TextVariants.Small}>
                ユーザーネーム
              </Text>
              <Box width="100%" flex>
                <TextInput
                  value={username}
                  placeholder=""
                  onChange={setUsername}
                  style={{ flex: 1 }}
                />
              </Box>
              <Spacer y={20} />
              <Text color={Colors.Grey} size={TextVariants.Small}>
                プロフィール
              </Text>
              <Box width="100%" flex>
                <TextInput
                  value={description}
                  placeholder=""
                  onChange={setDescription}
                  style={{ flex: 1 }}
                />
              </Box>
              <Spacer y={20} />

              <FileUploadComponent
                onFileUpload={(file) => {
                  if (file) {
                    setAvatar(file);
                  }
                }}
                component={(onButtonClick) => (
                  <Button text="アバター" onPress={onButtonClick} bold />
                )}
              />

              <Spacer y={20} />

              <FileUploadComponent
                onFileUpload={(file) => {
                  if (file) {
                    setBackground(file);
                  }
                }}
                component={(onButtonClick) => (
                  <Button text="背景" onPress={onButtonClick} bold />
                )}
              />

              <Spacer y={40} />
              {error ? (
                <>
                  <Text color={Colors.Grey} size={TextVariants.Small}>
                    {error}
                  </Text>
                  <Spacer y={20} />
                </>
              ) : null}

              <Button text="更新" onPress={submit} bold />
            </Box>
          </Box>
        </Box>
      </Box>

      <LoadingOverlay display={loading} />
    </>
  );
};
