import React from "react";

export const Touchable = ({
  children,
  onPress,
  enableOpacity,
  flex,
  style,
}: {
  children: React.ReactNode;
  onPress: () => void;
  enableOpacity?: boolean;
  flex?: boolean;
  style?: React.CSSProperties;
}) => {
  const basicStyle = {
    ...style,
    ...(flex
      ? {
          flex: 1,
        }
      : {}),
    opacity: enableOpacity ? 0.8 : 1,

    cursor: "pointer",
  };

  return (
    <div
      // onPress={handleOnPress}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onPress();
      }}
      style={basicStyle}
    >
      {children}
    </div>
  );
};
