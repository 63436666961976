import React from "react";

import AddIcon from "./AddIcon";
import BellIcon from "./BellIcon";
import BottomIcon from "./BottomIcon";
import CheckIcon from "./CheckIcon";
import HashtagIcon from "./HashtagIcon";
import HomeIcon from "./HomeIcon";
import ImageIcon from "./ImageIcon";
import LeftIcon from "./LeftIcon";
import ProfileIcon from "./ProfileIcon";
import ReplyIcon from "./ReplyIcon";
import RePostIcon from "./RePostIcon";
import SaveIcon from "./SaveIcon";
import SaveIconFilled from "./SaveIconFilled";
import ShieldIcon from "./ShieldIcon";
import SettingsIcon from "./SettingsIcon";
import SearchIcon from "./SerarchIcon";
import ShareIcon from "./ShareIcon";
import TripleDotIcon from "./TripleDotIcon";
import TripleLineIcon from "./TripleLineIcon";
import XIcon from "./XIcon";
import { Colors } from "../../../Mono/consts/Colors";
import PenIcon from "./PenIcon";
import UploadIcon from "./UploadIcon";

export enum IconType {
  Home = "home",
  Bell = "bell",
  Hashtag = "hashtag",

  Profile = "profile",
  Save = "save",
  Repost = "repost",
  Add = "add",
  Image = "image",
  AddImage = "add-image",
  AddText = "add-text",
  Bottom = "bottom",
  SaveFilled = "save-filled",
  Shield = "shield",

  TripleDot = "triple-dot",
  TripleLine = "triple-line",

  SaveWhite = "save-white",

  Pen = "pen",
  Upload = "upload",

  Left = "left",

  Reply = "reply",

  Settings = "settings",
  Search = "search",

  Share = "share",

  X = "x",

  Check = "check",
}

const getIconByType = (
  type: IconType,
  { size, color = Colors.White }: { size?: number; color?: Colors }
) => {
  switch (type) {
    case IconType.Home:
      return (
        <HomeIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Bell:
      return (
        <BellIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Hashtag:
      return (
        <HashtagIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Profile:
      return (
        <ProfileIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Save:
      return (
        <SaveIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );

    case IconType.SaveFilled:
      return (
        <SaveIconFilled
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Shield:
      return (
        <ShieldIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Repost:
      return (
        <RePostIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Bottom:
      return (
        <BottomIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Add:
      return (
        <AddIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Image:
      return (
        <ImageIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.X:
      return (
        <XIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.TripleDot:
      return (
        <TripleDotIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.TripleLine:
      return (
        <TripleLineIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Left:
      return (
        <LeftIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Check:
      return (
        <CheckIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Settings:
      return (
        <SettingsIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Search:
      return (
        <SearchIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Reply:
      return (
        <ReplyIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );
    case IconType.Share:
      return (
        <ShareIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );

    case IconType.Pen:
      return (
        <PenIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );

    case IconType.Upload:
      return (
        <UploadIcon
          style={{
            width: size,
            height: size,
          }}
          color={color}
        />
      );

    default:
      return null;
  }
};

export const Icon = ({
  type,
  size,
  color,
}: {
  type: IconType;
  size?: number;
  color?: Colors;
}) => getIconByType(type, { size, color });
