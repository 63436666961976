import React from "react";

import { Box, Spacer, Touchable, Text, HStack } from "../../UI";

import { Colors } from "../../../../Mono/consts/Colors";

export const Tabs = ({
  tabs,
  selectedTab,
  setSelectedTab,
}: {
  tabs: Array<{
    label: string;
    value: string;
  }>;
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
}) => {
  return (
    <Box width="100%">
      <Spacer y={10} />
      <HStack>
        <HStack>
          <Spacer x={15} />

          {tabs.map((tab, index) => (
            <Touchable onPress={() => setSelectedTab(tab.value)} key={index}>
              <Box spacing={8}>
                <Text color={Colors.White}>{tab.label}</Text>
              </Box>
              <Box
                color={tab.value === selectedTab ? Colors.White : Colors.Black3}
                borderRadius={15}
                height={3}
              />
            </Touchable>
          ))}
          <Spacer x={15} />
        </HStack>
      </HStack>
      <Spacer y={10} />
    </Box>
  );
};
