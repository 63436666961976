import React from "react";
import { NotificationsLoading, SmallPostField } from "../../Components/UX";
import {
  Box,
  Spacer,
  VStack,
  Text,
  Touchable,
  HStack,
  TextVariants,
} from "../../Components/UI";
import { Colors } from "../../../Mono/consts/Colors";
import {
  FollowNotification,
  NotificationUser,
  ReplyNotification,
  SaveNotification,
} from "../../../Mono/types";
import { postContentSplit } from "../../../Mono/tools/postExtract";
import { useGetNotifications } from "../../../Mono/queryHooks";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Icon, IconType } from "../../assets/icons";
import { RouteName } from "../../../Routes";
import { useLinkTo } from "../../../hooks/useLinkTo";

export const Notification = () => {
  const mode = useGetWindowMode();
  const linkTo = useLinkTo();

  const { val: notifications, loading } = useGetNotifications();

  return (
    <>
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box center>
        <Box
          style={{
            width: "100%",
            maxWidth: 900,
          }}
        >
          {loading ? (
            <NotificationsLoading />
          ) : !notifications?.length ? (
            <Box height="100%">
              <VStack center>
                <Spacer y={20} />
                <Text color={Colors.Grey} text="通知はありません" />
              </VStack>
            </Box>
          ) : (
            <>
              {notifications?.map((notification) => (
                <>
                  <Box
                    color={notification.seen ? Colors.Black3 : Colors.Black1}
                  >
                    <Spacer y={10} />
                    {notification.type === "follow" && (
                      <FollowNotificationComponent
                        followNotification={notification}
                        key={notification.id}
                      />
                    )}
                    {notification.type === "save" && (
                      <Touchable
                        enableOpacity
                        onPress={() => {
                          linkTo(RouteName.Post, {
                            postId: notification?.post.id,
                            pagination: 0,
                          });
                        }}
                      >
                        <SaveNotificationComponent
                          saveNotification={notification}
                          key={notification.id}
                        />
                      </Touchable>
                    )}
                    {notification.type === "reply" && (
                      <Touchable
                        enableOpacity
                        onPress={() => {
                          linkTo(RouteName.Post, {
                            postId: notification?.post.id,
                            pagination: 0,
                          });
                        }}
                      >
                        <ReplyNotificationComponent
                          replyNotification={notification}
                          key={notification.id}
                        />
                      </Touchable>
                    )}

                    <Spacer y={10} />
                    <Box color={Colors.Grey2} height={1} />
                  </Box>
                </>
              ))}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

// const SaveNotificationComponent = ({
//   saveNotification,
// }: {
//   saveNotification: SaveNotification;
// }) => {
//   const postContent = postContentSplit(saveNotification.post.content);
//   return (
//     <Box spacing={10}>
//       <HStack spaceBetween>
//         <Text
//           color={Colors.White}
//           size={TextVariants.Small}
//           text={`${saveNotification.saveCount} つの新しい保存`}
//         />
//         <Text
//           color={Colors.Grey}
//           size={TextVariants.Small}
//           text={formatDateToISO(saveNotification.date)}
//         />
//       </HStack>
//       <Box
//         color={Colors.Black2}
//         width={200}
//         borderRadius={15}
//         style={{
//           maxHeight: 120,
//           overflow: "hidden",
//           opacity: 0.3,
//         }}
//       >
//         <SmallPostField postContent={postContent} />
//       </Box>
//     </Box>
//   );
// };

// const FollowNotificationComponent = ({
//   followNotification,
// }: {
//   followNotification: FollowNotification;
// }) => {
//   return (
//     <Box spacing={10}>
//       <HStack spaceBetween>
//         <Text
//           color={Colors.White}
//           size={TextVariants.Small}
//           text={`${followNotification.followCount} つの新しい保存`}
//         />
//         <Text
//           color={Colors.Grey}
//           size={TextVariants.Small}
//           text={formatDateToISO(followNotification.date)}
//         />
//       </HStack>
//     </Box>
//   );
// };

// const ReplyNotificationComponent = ({
//   replyNotification,
// }: {
//   replyNotification: ReplyNotification;
// }) => {
//   return (
//     <Box spacing={10}>
//       <HStack spaceBetween>
//         <Text
//           color={Colors.White}
//           size={TextVariants.Small}
//           text={`${replyNotification.replyCount} つの新しいリプライ`}
//         />
//         <Text
//           color={Colors.Grey}
//           size={TextVariants.Small}
//           text={formatDateToISO(replyNotification.date)}
//         />
//       </HStack>
//     </Box>
//   );
// };

const NotificationUserAvatars = ({ users }: { users: NotificationUser[] }) => {
  // const { navigate } = useNavigator();

  const linkTo = useLinkTo();

  const displayUsers = users.slice(0, 28);
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
      spacing={{
        right: 40,
      }}
    >
      {displayUsers.map((user) => (
        <Touchable
          key={user.hangName}
          onPress={() =>
            linkTo(RouteName.Profile, {
              hangName: user.hangName,
            })
          }
        >
          <Box style={{ padding: 5, borderRadius: 100 }} color={Colors.Black3}>
            <img
              src={user.avatar}
              style={{
                width: 35,
                height: 35,
                borderRadius: 100,
              }}
            />
          </Box>
        </Touchable>
      ))}
    </Box>
  );
};

const SaveNotificationComponent = ({
  saveNotification,
}: {
  saveNotification: SaveNotification;
}) => {
  const postContent = saveNotification?.post?.content
    ? postContentSplit(saveNotification.post.content)
    : null;
  return (
    <Box>
      <HStack center>
        <Spacer x={10} />
        <Icon type={IconType.Save} size={23} />
        <Spacer x={6} />
        <NotificationUserAvatars users={saveNotification.users} />
      </HStack>

      <Box spacing={10}>
        <Text
          color={Colors.White}
          size={TextVariants.Small}
          text={`${saveNotification?.users[0]?.name}${
            saveNotification.users.length === 1
              ? "が"
              : `と他${saveNotification.users?.length - 1}人が`
          }ポストを保存しました`}
        />

        <Spacer y={10} />
        <Box
          color={Colors.Black2}
          width={200}
          borderRadius={15}
          style={{
            maxHeight: 120,
            overflow: "hidden",
            opacity: 0.3,
          }}
        >
          {postContent ? <SmallPostField postContent={postContent} /> : null}
        </Box>
      </Box>
    </Box>
  );
};

const FollowNotificationComponent = ({
  followNotification,
}: {
  followNotification: FollowNotification;
}) => {
  return (
    <Box>
      <HStack center>
        <Spacer x={10} />
        <Icon type={IconType.Check} size={23} />
        <Spacer x={6} />
        <NotificationUserAvatars users={followNotification.users} />
      </HStack>

      <Box spacing={10}>
        <Text
          color={Colors.White}
          size={TextVariants.Small}
          text={`${followNotification?.users[0]?.name || ""}${
            followNotification.users.length === 1
              ? "が"
              : `と他${followNotification.users.length - 1}人が`
          }フォローしました`}
        />
      </Box>
    </Box>
  );
};

const ReplyNotificationComponent = ({
  replyNotification,
}: {
  replyNotification: ReplyNotification;
}) => {
  const postContent = replyNotification?.post?.content
    ? postContentSplit(replyNotification.post.content)
    : null;
  return (
    <Box>
      <HStack center>
        <Spacer x={10} />
        <Icon type={IconType.Reply} size={23} />
        <Spacer x={6} />
        <NotificationUserAvatars users={replyNotification.users} />
      </HStack>

      <Box spacing={10}>
        <Text
          color={Colors.White}
          size={TextVariants.Small}
          text={`${replyNotification?.users[0]?.name || ""}${
            replyNotification.users.length === 1
              ? "が"
              : `と他${replyNotification.users.length - 1}人が`
          }ポストにリプライしました`}
        />
        <Spacer y={10} />
        <Box
          color={Colors.Black2}
          width={200}
          borderRadius={15}
          style={{
            maxHeight: 120,
            overflow: "hidden",
            opacity: 0.3,
          }}
        >
          {postContent ? <SmallPostField postContent={postContent} /> : null}
        </Box>
      </Box>
    </Box>
  );
};
