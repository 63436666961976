import React from "react";

const ProfileIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <circle cx="6.5" cy="3" r="1" fill={color} />
    <circle cx="6.5" cy="6" r="1" fill={color} />
    <circle cx="6.5" cy="9" r="1" fill={color} />
  </svg>
);

export default ProfileIcon;
