import React from "react";
import { Box, HStack, Spacer, Text, TextVariants } from "../UI";

import { Icon, IconType } from "../../assets/icons";
import { TextField } from "./PostField";

import { Button } from "../UI/Button";
import { User } from "../../../Mono/types";
import { Colors } from "../../../Mono/consts/Colors";
import { PostContentType } from "../../../Mono/tools/postExtract";

export const ProfileInfoSelf = ({
  user,

  onPressEditProfile,
  onPressUrl,
  onPressShare,
}: {
  user: User | null;

  onPressEditProfile: () => void;
  onPressUrl: (url: string) => void;
  onPressShare: () => void;
}) => (
  <>
    {user?.background ? (
      <Box height={150} borderRadius={20} style={{ position: "relative" }}>
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 20,
            backgroundColor: Colors.Black2,
          }}
          src={user?.background}
          alt="background"
        />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        />
      </Box>
    ) : (
      <Box height={150} borderRadius={20} color={Colors.Black2} />
    )}
    <Box spacing={10}>
      <HStack spaceBetween>
        <Box
          color={Colors.Black3}
          style={{
            padding: 7,
            borderRadius: 1000,
            marginTop: -50,
            position: "relative",
          }}
        >
          {user?.avatar ? (
            <img
              src={user?.avatar}
              style={{
                width: 80,
                height: 80,
                borderRadius: 1000,
                backgroundColor: Colors.Black2,
              }}
              alt="avatar"
            />
          ) : (
            <Box
              color={Colors.Black2}
              style={{ width: 80, height: 80 }}
              borderRadius={1000}
            />
          )}
          {user?.role === "moderator" ? (
            <Box
              color={Colors.Pink}
              style={{
                position: "absolute",
                width: 35,
                height: 35,
                borderRadius: 30,
                borderWidth: 4,
                borderColor: Colors.Black3,

                alignItems: "center",
                justifyContent: "center",
                display: "flex",

                right: 0,
                bottom: 0,
                zIndex: 10,
              }}
            >
              <Icon type={IconType.Shield} size={17} />
            </Box>
          ) : null}
        </Box>
        <Spacer x={10} />

        <HStack flex center>
          <Button
            text="編集"
            icon={IconType.Pen}
            onPress={onPressEditProfile}
            flex
          />
          <Spacer x={10} />
          <Button
            text="共用"
            icon={IconType.Upload}
            onPress={onPressShare}
            flex
          />
        </HStack>
      </HStack>
      <Spacer y={10} />
      <Box
        spacing={{
          left: 10,
        }}
      >
        <Text size={TextVariants.Larger} text={user?.name || ""} />
      </Box>
      <Spacer y={5} />
      <Box spacing={{ left: 10, right: 10 }}>
        <Text
          color={Colors.Grey}
          size={TextVariants.Smaller}
          text={`@${user?.hangName}` || ""}
        />
      </Box>
      <Spacer y={15} />
      {user?.description && (
        <Box spacing={{ left: 10, right: 10, bottom: 15 }}>
          <TextField
            postContent={{
              type: PostContentType.String,
              content: user?.description,
            }}
            size={TextVariants.Small}
            onPressUrl={onPressUrl}
          />
        </Box>
      )}

      <Box
        spacing={{
          left: 10,
          right: 10,
        }}
      >
        <HStack>
          <HStack center>
            <Text text={user?.followingCount} size={TextVariants.Small} />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロー"
              size={TextVariants.Smaller}
            />
          </HStack>
          <Spacer x={20} />
          <HStack center>
            <Text text={user?.followerCount} size={TextVariants.Small} />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロワー"
              size={TextVariants.Smaller}
            />
          </HStack>
        </HStack>
      </Box>
    </Box>
  </>
);
