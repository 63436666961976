import React from "react";
import service from "../../../Mono/service";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { Box, Button, HStack, Spacer, Text } from "../../Components/UI";
import { TextInput } from "../../Components/UX";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";

const checkHangName = (hangName: string) => {
  if (!hangName) {
    return "ハングネームを入力してください";
  }

  if (hangName?.length < 3) {
    return "ハングネームは3文字以上でなければなりません";
  }
  // no longer than 20 characters
  if (hangName?.length > 20) {
    return "ハングネームは20文字以下でなければなりません";
  }
  // no spaces
  if (hangName?.includes(" ")) {
    return "ハングネームにスペースを含めることはできません";
  }
  // no special characters aside from underscore
  if (hangName?.match(/[^a-zA-Z0-9_]/)) {
    return "ハングネームに特殊文字を含めることはできません";
  }
  // no uppercase
  if (hangName?.match(/[A-Z]/)) {
    return "ハングネームに大文字を含めることはできません";
  }

  return null;
};

export const EditHangName = () => {
  const mode = useGetWindowMode();

  const linkTo = useLinkTo();

  const [hangName, setHangName] = React.useState(
    service.state.user?.hangName || ""
  );

  const error = checkHangName(hangName);

  const saveName = () => {
    if (error || !hangName) return;
    service
      .api()
      .users.updateHangName(hangName)
      .then(async () => {
        service.refetchUser().then(() => {
          linkTo(RouteName.AccountSettings);
        });
      });
  };

  const onChange = (text: string) => {
    setHangName(text.toLowerCase());
  };

  return (
    <>
      <Spacer y={mode === "web" ? 120 : 20} />
      <Box center>
        <Box
          style={{
            maxWidth: 630,
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Spacer y={20} />

          <Text text="ハングネームを変更する" />

          <Box height={20} />
          <HStack center>
            <Box spacing={{ right: 10 }}>
              <Text text="@" />
            </Box>
            <TextInput
              placeholder="ハングネーム"
              value={hangName}
              onChange={onChange}
              style={{ display: "flex", flex: 1 }}
            />
          </HStack>
          <Box height={20} />
          {error ? <Text text={error} /> : null}

          <Spacer y={20} />

          <Button onPress={saveName} bold text="保存" />
        </Box>
      </Box>
    </>
  );
};
