import React from "react";
import {
  ImageContent,
  PostContent,
  PostContentType,
  TextContent,
} from "../../../Mono/tools/postExtract";
import { Colors } from "../../../Mono/consts/Colors";
import { Box } from "../UI/Box";
import { Touchable } from "../UI/Touchable";
import { Spacer } from "../UI/Spacer";
import { VStack } from "../UI";

export const PostField = ({
  postContent,
  onPressImage,
  onPressUrl,
  textSpacing = 20,
  textSize = 20,
}: {
  postContent: PostContent[];
  onPressImage: (image: ImageContent) => void;
  onPressUrl: (url: string) => void;
  textSpacing?: number;
  textSize?: number;
}) => {
  return (
    <>
      {postContent.map((content, index) => (
        <>
          {content.type === PostContentType.Image ? (
            <Touchable onPress={() => onPressImage(content)}>
              <Box key={index}>
                <ImageField image={content} />
              </Box>
            </Touchable>
          ) : (
            <Box spacing={textSpacing}>
              <TextField
                postContent={content}
                key={index}
                onPressUrl={onPressUrl}
                size={textSize}
              />
            </Box>
          )}

          {index !== postContent.length - 1 && <Spacer y={5} />}
        </>
      ))}
    </>
  );
};

export const SmallPostField = ({
  postContent,
}: {
  postContent: PostContent[];
}) => {
  if (!postContent.length) {
    return null;
  }

  const slicedContent = postContent.slice(0, 2);

  return (
    <VStack>
      {slicedContent.map((content, index) => (
        <>
          {content.type === PostContentType.Image ? (
            <Box key={index}>
              <ImageField image={content} />
            </Box>
          ) : (
            <Box spacing={10}>
              <TextField postContent={content} key={index} />
            </Box>
          )}

          {index !== postContent?.length - 1 && <Spacer y={5} />}
        </>
      ))}
    </VStack>
  );
};

const separateURLs = (
  text: string
): Array<
  | {
      text: string;
      type: "text";
    }
  | {
      url: string;
      type: "url";
    }
> => {
  const urls = text.match(/https?:\/\/\S+/g);
  const texts = text.split(/https?:\/\/\S+/g);

  if (!urls)
    return [
      {
        text: text,
        type: "text",
      },
    ];

  return texts.reduce(
    (
      acc: Array<
        | {
            text: string;
            type: "text";
          }
        | {
            url: string;
            type: "url";
          }
      >,
      text,
      index
    ) => {
      if (index === texts.length - 1)
        return [
          ...acc,
          {
            text: text,
            type: "text",
          },
        ];

      return [
        ...acc,
        {
          text: text,
          type: "text",
        },
        {
          url: urls[index],
          type: "url",
        },
      ];
    },
    []
  );
};

export const TextField = ({
  postContent,
  size,
  onPressUrl,
}: {
  postContent: TextContent;
  size?: number;
  onPressUrl?: (url: string) => void;
}) => {
  const content = separateURLs(postContent.content);
  return (
    <p
      style={{
        margin: 0,
        whiteSpace: "pre-wrap",
        overflowWrap: "anywhere",
      }}
    >
      {content.map((item, index) =>
        item.type === "url" ? (
          onPressUrl ? (
            <span
              onClick={() => {
                onPressUrl(item.url);
              }}
              style={{
                color: Colors.Blue,
                fontSize: size,
                margin: 0,
                cursor: "pointer",
              }}
            >
              {item.url}
            </span>
          ) : (
            <span
              key={index}
              style={{ color: Colors.Blue, fontSize: size, margin: 0 }}
            >
              {item.url}
            </span>
          )
        ) : (
          <span
            key={index}
            style={{ color: Colors.White, fontSize: size, margin: 0 }}
          >
            {item.text}
          </span>
        )
      )}
    </p>
  );
};

const ImageField = ({ image }: { image: ImageContent }) => (
  <img
    src={image.imageUrl}
    alt={image.imageUrl}
    style={{
      width: "100%",

      verticalAlign: "text-bottom",
      ...(image.params
        ? {
            aspectRatio: image.params?.width / image.params?.height,
            backgroundColor: image.params?.color,
          }
        : {}),
    }}
  />
);
