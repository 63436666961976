import "firebase/compat/auth";
import firebase from "firebase/compat/app";
import React from "react";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { YgraLogo } from "../../assets/YgraLogo";
import { Colors } from "../../../Mono/consts/Colors";
import {
  Box,
  Button,
  // ButtonSizes,
  HStack,
  Spacer,
  Text,
  TextVariants,
  Touchable,
  VStack,
} from "../../Components/UI";
import { ScreenContainer, TextInput } from "../../Components/UX";
import { termsUri } from "../../../Mono/consts/termsUri";
import { LoadingOverlay } from "../../Components/UX/LoadingOverlay";

export const LoginPage = () => {
  return (
    <div
      style={{
        zIndex: 100,
        position: "absolute",
        height: "100vh",
        width: "100vw",
        backgroundColor: Colors.Black3,
      }}
    >
      <LoginPageContainer />
    </div>
  );
};
export const LoginPageContainer = () => {
  const mode = useGetWindowMode();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [status, setStatus] = React.useState<"login" | "signup">("login");
  const [loading, setLoading] = React.useState(false);

  const onLogIn = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onSignUp = () => {
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onSubmit = () => (status === "login" ? onLogIn() : onSignUp());

  const [agreed, setAgreed] = React.useState(false);
  if (!agreed) return <AgreementPage onAgreed={() => setAgreed(true)} />;

  return (
    <Box center height="80vh">
      <Box
        width="100%"
        style={{
          maxWidth: 350,
        }}
      >
        <Spacer y={mode === "web" ? 150 : 70} />

        <VStack center>
          <YgraLogo style={{ width: 90 }} />
        </VStack>

        <Spacer y={60} />
        <HStack spaceBetween>
          <Touchable onPress={() => setStatus("login")}>
            <Box spacing={20}>
              <Text color={status === "login" ? Colors.White : Colors.Grey}>
                ログイン
              </Text>
            </Box>
          </Touchable>

          <Touchable onPress={() => setStatus("signup")}>
            <Box spacing={20}>
              <Text color={status === "signup" ? Colors.White : Colors.Grey}>
                アカウント制作
              </Text>
            </Box>
          </Touchable>
        </HStack>

        <Box flex color={Colors.Grey} height={1} />

        <Spacer y={30} />

        <VStack>
          <TextInput
            value={email}
            placeholder={"メールアドレス"}
            onChange={setEmail}
          />

          <Spacer y={20} />

          <TextInput
            value={password}
            placeholder={"パスワード"}
            type="password"
            onChange={setPassword}
          />
        </VStack>

        <Spacer y={10} />

        {status === "signup" ? (
          <Box>
            <p style={{ color: Colors.Grey }}>
              新規登録することにより
              <a href={termsUri} style={{ color: Colors.White }}>
                ゆらぐら利用規約
              </a>
              へ同意することを示すものとします。
            </p>
          </Box>
        ) : (
          <Spacer y={10} />
        )}

        <Spacer y={10} />

        <Button
          text={status === "login" ? "ログイン" : "アカウント制作"}
          onPress={onSubmit}
          bold
        />

        <Spacer y={100} />
      </Box>
      <LoadingOverlay display={loading} />
    </Box>
  );
};

const termsStyle: React.CSSProperties = {
  color: Colors.White,
  fontSize: TextVariants.Small,
  letterSpacing: 0.9,
  textAlign: "center",
  // underline
  textDecorationLine: "underline",
};

const AgreementPage = ({ onAgreed }: { onAgreed: () => void }) => {
  const mode = useGetWindowMode();
  return (
    <Box color={Colors.Black3} center>
      <Box
        style={{
          width: "100%",
          maxWidth: 700,
          zIndex: 1,
        }}
      >
        <Spacer y={mode === "web" ? 70 : 10} />
        <Box
          style={{
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <VStack center>
            <Box spacing={20}>
              <img
                src={require("./guy.png")}
                style={{
                  width: 100,
                  height: 90,
                }}
                alt="guy"
              />
            </Box>
          </VStack>

          <VStack center>
            <Text bold>ゆらぐら利用規約</Text>
          </VStack>

          <VStack>
            <Text>1. リア友のお誘い禁止</Text>
            <Spacer y={10} />
            <Text color={Colors.Grey} size={TextVariants.Small}>
              他プラットフォーム (Xとか)
              での共用アリ、ただ、リアルに知っている人には共用ナシ
            </Text>
          </VStack>
          <VStack>
            <Text>2. AI学習禁止</Text>
            <Spacer y={10} />
            <Text color={Colors.Grey} size={TextVariants.Small}>
              このプラットフォーム上の投稿をAIの学習に使用することはできません
            </Text>
          </VStack>
          <VStack>
            <Text>3. イラスト、テキスト盗難禁止</Text>
            <Spacer y={10} />
            <Text color={Colors.Grey} size={TextVariants.Small}>
              当プラットフォームのアート、テキストを許可なくアップロードをするな
            </Text>
          </VStack>
          <VStack>
            <Text>4. その他利用客に同意する</Text>
            <Spacer y={10} />
            <Text color={Colors.Grey} size={TextVariants.Small}>
              その他
              <a href={termsUri} style={termsStyle}>
                ゆらぐら利用規約
              </a>
              へ同意する（詐欺はダメなど）
            </Text>
          </VStack>

          <Button
            bold
            text="同意する"
            onPress={onAgreed}
            styles={{ padding: 10 }}
          />
        </Box>
        <Spacer y={mode === "web" ? 10 : 100} />
      </Box>
    </Box>
  );
};
