import React from "react";

const ImageIcon = ({
  style,
  color,
}: {
  style?: React.CSSProperties;
  color: string;
}) => (
  <svg viewBox="0 0 13 13" fill="none" style={style}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.50005 2C2.22391 2 2.00005 2.22386 2.00005 2.5V8.06281C1.99998 8.06768 1.99998 8.07254 2.00005 8.0774V10.6029C2.00005 10.879 2.22391 11.1029 2.50005 11.1029H10.6028C10.8789 11.1029 11.1028 10.879 11.1028 10.6029V8.08135C11.1029 8.07441 11.1029 8.06748 11.1028 8.06055V2.5C11.1028 2.22386 10.8789 2 10.6028 2H2.50005ZM10.1028 7.26155V3H3.00005V6.41916L4.10971 4.75464C4.20244 4.61554 4.35856 4.53199 4.52574 4.53199C4.69292 4.53199 4.84903 4.61554 4.94177 4.75464L6.72261 7.42595L8.3535 6.6105C8.49426 6.54011 8.65995 6.54011 8.80072 6.6105L10.1028 7.26155ZM3.00005 8.22195V10.1029H10.1028V8.37958L8.57711 7.61673L6.77503 8.51778C6.54949 8.63055 6.27527 8.55773 6.1354 8.34792L4.52574 5.93339L3.00005 8.22195Z"
      fill={color}
    />
  </svg>
);

export default ImageIcon;
