import { useParams } from "react-router-dom";
import { MasonryComponent } from "../../Components/UX/Masonry";
import React from "react";
import { useLinkTo } from "../../../hooks/useLinkTo";
import { RouteName } from "../../../Routes";
import {
  updateUserCache,
  useGetUserByHangName,
  useGetUserPosts,
} from "../../../Mono/queryHooks";
import service from "../../../Mono/service";
import { useGetWindowMode } from "../../../hooks/useGetWindowMode";
import { ProfileInfo } from "../../Components/UX/ProfileInfo";
import { Box, Spacer } from "../../Components/UI";
import { PageContainer } from "../../Components/UX/Layout/PageContainer";
import { ProfileInfoLoading } from "../../Components/UX/ProfileInfoLoading";
import { useBottomSheet } from "../../BottomSheet/BottomSheetContext";
import { Post, User } from "../../../Mono/types";
import { ProfileInfoSelf } from "../../Components/UX/ProfileInfoSelf";
import { ShareModal } from "../../Components/UX/ShareModal";
import { useScrollEnd } from "../../../hooks/useScrollEnd";

export const UserProfile = () => {
  let { hangName } = useParams();

  const [shareModalOpen, setShareModalOpen] = React.useState(false);

  const mode = useGetWindowMode();
  const linkTo = useLinkTo();

  const bottomSheet = useBottomSheet();

  React.useEffect(() => {
    if (!hangName) return;
    if (service.state.posts.length < 2) {
      setTimeout(function () {
        window.location.href = `yuragura://user/${hangName}`;
      }, 500);
    }
  }, []);

  const isLoggedInUserProfile = service.state.user?.hangName === hangName;

  const {
    val: user,
    reload,
    setVal: setUser,
  } = useGetUserByHangName(hangName || "");

  const { isAtEnd } = useScrollEnd(2);

  React.useEffect(() => {
    if (isAtEnd) getMore();
  }, [isAtEnd]);

  const {
    posts,
    getMore,
    reload: reloadPosts,
  } = useGetUserPosts(user?.id || "");

  const updateUser = (user: User) => {
    setUser(user);
    updateUserCache([user]);
  };

  const onFollow = async () => {
    if (!user) return;
    const updatedUser: User = {
      ...user,
      isFollowing: true,
      followerCount: user?.followerCount + 1,
    };
    await updateUser(updatedUser);
    return service
      .api()
      .follows.createFollow(user?.id || "")
      .then(reload);
  };

  const onUnFollow = async () => {
    if (!user) return;
    const updatedUser: User = {
      ...user,
      isFollowing: false,
      followerCount: user?.followerCount - 1,
    };
    await updateUser(updatedUser);
    return service
      .api()
      .follows.deleteFollow(user?.id || "")
      .then(reload);
  };

  const onBlockUser = async () => {
    if (!user) return;
    const updatedUser: User = {
      ...user,
      isBlocked: true,
    };
    await updateUser(updatedUser);
    return service.api().blocks.createBlock(user.id).then(reload);
  };

  const onUnBlockUser = async () => {
    if (!user) return;
    const updatedUser: User = {
      ...user,
      isBlocked: false,
    };
    await updateUser(updatedUser);
    return service
      .api()
      .blocks.deleteBlock(user.id)
      .then(reload)
      .then(reloadPosts);
  };

  const onClickEditUser = () => {
    linkTo(RouteName.ProfileEdit);
  };

  const onPressUrl = (url: string) => {
    window.open(url, "_blank");
  };

  const onClickTripleDot = () => {
    if (!user) return;
    bottomSheet.openUserModal({
      userId: user.id,
      isBlocked: user?.isBlocked,
      onBlockUser,
    });
  };

  const onClickPost = (post: Post) => {
    const postIndex = posts?.findIndex((p) => p.id === post.id);
    linkTo(RouteName.Post, {
      postId: post.id,
      pagination: (postIndex || 1) + 1,
    });
  };

  const userUrl = `https://yuragura.com/users/${user?.hangName}`;

  const onPressShare = async () => {
    setShareModalOpen(true);
  };
  const onCloseShare = () => {
    setShareModalOpen(false);
  };

  return (
    <PageContainer onEndReached={getMore}>
      <Spacer y={mode === "web" ? 110 : 10} />
      <Box center>
        <Box
          style={{
            width: "95%",

            maxWidth: 900,
          }}
        >
          {isLoggedInUserProfile ? (
            <ProfileInfoSelf
              user={user}
              onPressEditProfile={onClickEditUser}
              onPressUrl={onPressUrl}
              onPressShare={onPressShare}
            />
          ) : user ? (
            <ProfileInfo
              user={user}
              onPressUrl={onPressUrl}
              onPressFollow={onFollow}
              onPressUnFollow={onUnFollow}
              onPressUnBlock={onUnBlockUser}
              onClickTripleDot={onClickTripleDot}
              isBlocked={user.isBlocked}
            />
          ) : (
            <ProfileInfoLoading />
          )}
        </Box>
      </Box>
      <Spacer y={10} />
      <Box center>
        <Box
          spacing={
            mode === "web"
              ? { left: 15, right: 15 }
              : {
                  left: 10,
                  right: 10,
                }
          }
          style={{
            width: "100%",
            maxWidth: 900,
            zIndex: 1,
          }}
        >
          {posts ? (
            <MasonryComponent posts={posts} onClick={onClickPost} />
          ) : null}
        </Box>
      </Box>
      <ShareModal url={userUrl} open={shareModalOpen} onClose={onCloseShare} />
    </PageContainer>
  );
};
