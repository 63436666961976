export enum Colors {
  // Black1 = "#131313",
  // Black2 = "#1F1F1F",
  // Black3 = "#000000",

  // White = "#e9e9e9",
  // Grey = "#6E6E6E",
  // Grey2 = "#3B3B3B",

  // Purple = "#6332A0",

  // Blue = "#00bfff",

  // Red = "#B04545",

  // Pink = "#885EE3",

  // LightBlue = "#4A66CA",
  Black1 = "#131313",
  Black2 = "#1F1F1F",
  Black3 = "#000000",

  White = "#e9e9e9",
  Grey = "#6E6E6E",
  Grey2 = "#3B3B3B",

  Purple = "#6332A0",

  Blue = "#00bfff",

  Red = "#e52525",

  Green = "#4bce1f",

  Pink = "#885EE3",

  LightBlue = "#4A66CA",
}
