import { Colors } from "../../../Mono/consts/Colors";
import {
  Text,
  Box,
  Button,
  HStack,
  Spacer,
  TextVariants,
  Touchable,
} from "../UI";

export const ShareModal = ({
  open,
  onClose,
  url,
}: {
  open: boolean;
  onClose: () => void;
  url: string;
}) => {
  const shareToTwitter = () => {
    window.open(
      `http://twitter.com/share?url=${url}`,
      "_blank",
      "width=550,height=420"
    );
  };

  const copyLink = () => {
    navigator.clipboard.writeText(url);
    alert("リンクをコピーしました");
  };

  if (!open) return null;

  return (
    <Box
      spacing={10}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
      }}
    >
      <Box
        color={Colors.Black1}
        borderRadius={20}
        width={300}
        height={300}
        spacing={20}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Text text="共用" size={TextVariants.Larger} bold />
          <Spacer y={20} />
          <Touchable onPress={copyLink}>
            <Box
              borderRadius={20}
              color={Colors.Black2}
              spacing={{
                left: 20,
                right: 20,
                top: 10,
                bottom: 10,
              }}
            >
              <HStack center>
                <Text
                  text="URL: "
                  size={TextVariants.Small}
                  color={Colors.Grey}
                />
                <Spacer x={10} />
                <Text
                  text={url}
                  size={TextVariants.Small}
                  color={Colors.White}
                  styles={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                />
              </HStack>
            </Box>
          </Touchable>
          <Spacer y={20} />
          <Touchable onPress={shareToTwitter}>
            <Box
              borderRadius={20}
              color={Colors.Black2}
              center
              spacing={{
                left: 20,
                right: 20,
                top: 10,
                bottom: 10,
              }}
            >
              <Text text="Xへシェア " size={TextVariants.Small} />
            </Box>
          </Touchable>
        </Box>
        <Button text="閉じる" bold onPress={onClose} />
      </Box>
    </Box>
  );
};
