import React from "react";
import {
  Box,
  HStack,
  Spacer,
  Text,
  TextVariants,
  Touchable,
  VStack,
} from "../UI";
import { ButtonVariants } from "../UI/Button";

import { Icon, IconType } from "../../assets/icons";
import { TextField } from "./PostField";

import { Button } from "../UI/Button";
import { Colors } from "../../../Mono/consts/Colors";
import { bannerUri } from "../../../Mono/consts/bannerUri";
import { PostContentType } from "../../../Mono/tools/postExtract";
import { User } from "../../../Mono/types";

export const ProfileInfo = ({
  user,
  isBlocked,

  onPressUrl,
  onPressFollow,
  onPressUnFollow,
  onPressUnBlock,
  onClickTripleDot,
}: {
  user: User;
  isBlocked?: boolean;

  onPressUrl: (url: string) => void;
  onPressFollow: () => void;
  onPressUnFollow: () => void;
  onPressUnBlock: () => void;
  onClickTripleDot: () => void;
}) => (
  <>
    {user?.background && !isBlocked ? (
      <Box
        height={150}
        borderRadius={20}
        style={{
          position: "relative",
        }}
      >
        <img
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: 10,
            backgroundColor: Colors.Black2,
          }}
          src={isBlocked ? "" : user?.background || bannerUri}
          alt="background"
        />
        <Box
          style={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        />
      </Box>
    ) : (
      <Box height={150} borderRadius={20} color={Colors.Black2} />
    )}
    <Box spacing={10}>
      <HStack spaceBetween>
        <Box
          color={Colors.Black3}
          style={{
            padding: 7,
            borderRadius: 1000,
            marginTop: -50,
            position: "relative",
          }}
        >
          {user?.avatar ? (
            <img
              loading="lazy"
              src={user?.avatar}
              style={{
                width: 80,
                height: 80,
                borderRadius: 1000,
                backgroundColor: Colors.Black2,
              }}
              alt="avatar"
            />
          ) : (
            <Box
              color={Colors.Black2}
              style={{ width: 80, height: 80 }}
              borderRadius={1000}
            />
          )}

          {user?.role === "moderator" ? (
            <Box
              color={Colors.Pink}
              style={{
                position: "absolute",
                width: 35,
                height: 35,
                borderRadius: 30,
                borderWidth: 4,
                borderColor: Colors.Black3,

                alignItems: "center",
                justifyContent: "center",
                display: "flex",

                right: 0,
                bottom: 0,
                zIndex: 10,
              }}
            >
              <Icon type={IconType.Shield} size={17} />
            </Box>
          ) : null}
        </Box>
        <Spacer x={10} />
        <HStack flex spaceBetween center>
          <Box>
            {isBlocked ? (
              <Button
                text="フォロー解除"
                bold
                onPress={onPressUnBlock}
                styles={{
                  width: 120,
                }}
              />
            ) : user?.isFollowing ? (
              <Button
                text="フォロー中"
                bold
                onPress={onPressUnFollow}
                variant={ButtonVariants.Black}
                styles={{
                  width: 120,
                }}
              />
            ) : (
              <Button
                text="フォロー"
                bold
                onPress={onPressFollow}
                styles={{
                  width: 120,
                }}
              />
            )}
          </Box>
          <Touchable onPress={onClickTripleDot}>
            <Box spacing={10}>
              <Icon type={IconType.TripleDot} size={20} />
            </Box>
          </Touchable>
        </HStack>
      </HStack>
      <Spacer y={10} />
      <Box
        spacing={{
          left: 10,
        }}
      >
        <Text size={TextVariants.Larger} text={user?.name || ""} />
      </Box>
      <Spacer y={5} />
      <Box spacing={{ left: 10, right: 10 }}>
        <Text
          color={Colors.Grey}
          size={TextVariants.Smaller}
          text={`@${user?.hangName}` || ""}
        />
      </Box>
      <Spacer y={15} />
      {user?.description && (
        <Box spacing={{ left: 10, right: 10, bottom: 15 }}>
          <TextField
            postContent={{
              type: PostContentType.String,
              content: user?.description,
            }}
            onPressUrl={onPressUrl}
          />
        </Box>
      )}
      <Box
        spacing={{
          left: 10,
          right: 10,
        }}
      >
        <HStack>
          <HStack center>
            <Text text={user?.followingCount} size={TextVariants.Small} />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロー"
              size={TextVariants.Smaller}
            />
          </HStack>
          <Spacer x={20} />
          <HStack center>
            <Text text={user?.followerCount} size={TextVariants.Small} />
            <Spacer x={5} />
            <Text
              color={Colors.Grey}
              text="フォロワー"
              size={TextVariants.Smaller}
            />
          </HStack>
        </HStack>
      </Box>
    </Box>
  </>
);
